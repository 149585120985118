import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "sales-orders page" }
const _hoisted_2 = {
  key: 0,
  class: "card"
}
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_badge = _resolveComponent("badge")!
  const _component_grid_table = _resolveComponent("grid-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (Object.keys(_ctx.pagedList).length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_grid_table, {
              columns: _ctx.columns,
              hasItemCustomActions: true,
              hasItemNotifications: true,
              hasNoDataNotification: true,
              iconClass: "fas fa-receipt",
              isItemAddable: false,
              isSearchable: true,
              isSortable: true,
              items: _ctx.pagedList.items,
              pageInfo: _ctx.pagedList.paging,
              title: _ctx.$t('reseller_sales-orders_title'),
              onChangePagedItems: _cache[0] || (_cache[0] = ($event: any) => (_ctx.getPagedItems($event))),
              onHyperlinkItem: _cache[1] || (_cache[1] = ($event: any) => (_ctx.navigateToSalesOrder($event.id)))
            }, {
              status: _withCtx((slotProps) => [
                _createVNode(_component_badge, {
                  badgeInfo: _ctx.$options.getSalesOrderStatusBadgeInfo(slotProps.item.status),
                  text: slotProps.item.status
                }, null, 8, ["badgeInfo", "text"])
              ]),
              "item-custom-action": _withCtx((slotProps) => [
                _createElementVNode("button", {
                  class: "btn btn-primary",
                  onClick: ($event: any) => (_ctx.navigateToSalesOrder(slotProps.item.id))
                }, _toDisplayString(_ctx.$t('reseller_view-sales-order_button')), 9, _hoisted_4)
              ]),
              _: 1
            }, 8, ["columns", "items", "pageInfo", "title"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}