// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import { BadgeType } from '@/_typings/enums/badgeType'
import { IColumn } from '@/_typings/interfaces/column'
import { IPagedList } from '@/_typings/interfaces/paged-list'
import Badge from '@/components/controls/badge/index.vue'
import DatePicker from '@/components/controls/date-picker/index.vue'
import GridTable from '@/components/controls/grid-table/index.vue'
import { getSalesOrderStatusBadgeInfo } from '@/utils/getBadgeInfo'

export default defineComponent({
  name: 'sales-orders',
  components: {
    Badge,
    DatePicker,
    GridTable
  },
  BadgeType,
  getSalesOrderStatusBadgeInfo,
  data() {
    return {
      pagedList: {} as IPagedList
    }
  },
  created() {
    this.getPagedItems({ sortedBy: 'name', sortDirection: 'Ascending' })
  },
  computed: {
    columns() : IColumn[] {
      return [
        { id: 'name', dataType: 'String', label: this.$t('sales-order_name_label'), name: 'name', isHyperlink: true },
        { id: 'accountName', dataType: 'String', label: this.$t('sales-order_account_label'), name: 'accountName' },
        { id: 'ownerName', dataType: 'String', label: this.$t('sales-order_owner_label'), name: 'ownerName' },
        { id: 'status', dataType: 'String', label: this.$t('sales-order_status_label'), name: 'status', width: '4rem', customRender: 'status' },
        { id: 'purchaseOrderNumber', dataType: 'String', label: this.$t('sales-order_purchase-order-number-abbreviated_label'), name: 'purchaseOrderNumber', width: '10rem' },
        { id: 'purchaseOrderDate', dataType: 'Date', label: this.$t('sales-order_purchase-order-date-abbreviated_label'), name: 'purchaseOrderDate', width: '10rem' }
      ] as IColumn[]
    }
  },
  methods: {
    ...mapActions([
      'fetchSalesOrders'
    ]),
    getPagedItems(pageInfo: any) {
      this.fetchSalesOrders({ pageInfo: { ...this.pagedList.paging, ...pageInfo } }).then(data => {
        this.pagedList = data
      })
    },
    navigateToSalesOrder(id) {
      this.$router.push({ name: 'sales-order', params: { salesOrderId: id } })
    }
  }
})
