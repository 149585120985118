// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED
import { defineComponent } from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { AlertType } from '@/_typings/enums/alertType'
import { BadgeType } from '@/_typings/enums/badgeType'
import { IColumn } from '@/_typings/interfaces/column'
import { IDropdownItem } from '@/_typings/interfaces/dropdown-item'
import { IPagedList } from '@/_typings/interfaces/paged-list'
import { ISalesOrder, ISalesOrderLine } from '@/_typings/interfaces/sales-order'
import Badge from '@/components/controls/badge/index.vue'
import ConfiguratorCart from '@/components/misc/configurator-cart/index.vue'
import ConfiguratorCartMixin from '@/components/misc/configurator-cart/configurator-cart-mixin'
import DatePicker from '@/components/controls/date-picker/index.vue'
import Dropdown from '@/components/controls/drop-down/index.vue'
import GridTable from '@/components/controls/grid-table/index.vue'
import NumberInput from '@/components/controls/number-input/index.vue'
import TextInput from '@/components/controls/text-input/index.vue'
import getPageTitle from '@/utils/getPageTitle'
import Localization from '@/utils/localization'
import { getSalesOrderStatusBadgeInfo } from '@/utils/getBadgeInfo'
import JQuery from 'jquery'

interface ISalesOrderStatus {
  id: string
  action: string;
  reason: string;
}

export default defineComponent({
  name: 'sales-order',
  components: {
    Badge,
    ConfiguratorCart,
    DatePicker,
    Dropdown,
    GridTable,
    NumberInput,
    TextInput
  },
  mixins: [ ConfiguratorCartMixin ],
  BadgeType,
  getPageTitle,
  getSalesOrderStatusBadgeInfo,
  Localization,
  data() {
    return {
      accountList: {} as IPagedList,
      contactList: {} as IPagedList,
      isCartReady: false,
      isSaving: false,
      opportunityList: {} as IPagedList,
      quoteList: {} as IPagedList,
      salesOrder: {} as ISalesOrder,
      salesOrderStatus: {} as ISalesOrderStatus,
      salesOrderStatuses: [] as (IDropdownItem & { action: string })[],
      readonly: false,
      selectedLineItems: [] as any[],
      userList: {} as IPagedList,
      validatorKey: 0
    }
  },
  created() {
    if (!!this.$route.params.salesOrderId) { // view / edit
      this.fetchSalesOrder({ salesOrderId: this.$route.params.salesOrderId }).then(data => {
        this.salesOrder = data
      })
      this.readonly = true
    } else { // new
      this.salesOrder = { status: 'Active' } as ISalesOrder
      this.fetchLoggedOnUser().then(data => { this.salesOrder.owner = data })
    }
    this.fetchAccounts({ pageInfo: { pageSize: 250, sortedBy: 'name', sortDirection: 'Ascending' }, salesOrderId: this.$route.params.salesOrderId, activeOnly: true }).then(data => { this.accountList = data })
    this.fetchContacts({ pageInfo: { pageSize: 250, sortedBy: 'fullName', sortDirection: 'Ascending' }, salesOrderId: this.$route.params.salesOrderId, activeOnly: true }).then(data => { this.contactList = { ...data, items: data.items.map(item => { return { ...item, name: item.fullName } }) } })
    this.fetchOpportunities({ pageInfo: { pageSize: 250, sortedBy: 'name', sortDirection: 'Ascending' }, salesOrderId: this.$route.params.salesOrderId, activeOnly: true }).then(data => { this.opportunityList = data })
    this.fetchQuotes({ pageInfo: { pageSize: 250, sortedBy: 'name', sortDirection: 'Ascending' }, salesOrderId: this.$route.params.salesOrderId, activeOnly: true }).then(data => { this.quoteList = data })
    this.fetchUsers({ pageInfo: { pageSize: 250, sortedBy: 'fullName', sortDirection: 'Ascending' } }).then(data => { this.userList = { ...data, items: data.items.map(item => { return { ...item, name: item.fullName } }) } })
  },
  computed: {
    ...mapGetters({
      user: 'getUser'
    }),
    isLocked(): boolean {
      return this.salesOrder.status === 'Cancelled' || this.salesOrder.status === 'Ordered' || this.salesOrder.status === 'Closed'
    },
    lineColumns() : IColumn[] {
      return [
        { id: 'sku', dataType: 'String', label: this.$t('sales-order-line_sku_column-header'), name: 'sku', isHyperlink: true },
        { id: 'name', dataType: 'String', label: this.$t('sales-order-line_name_column-header'), name: 'name' },
        { id: 'quantity', dataType: 'Numeric', label: this.$t('sales-order-line_quantity_column-header'), name: 'quantity', width: '10rem' },
        { id: 'unitCost', dataType: 'Currency', label: this.$t('sales-order-line_unit-cost_column-header'), name: 'unitCost', width: '10rem' },
        { id: 'unitPrice', dataType: 'Currency', label: this.$t('sales-order-line_unit-price_column-header'), name: 'unitPrice', width: '10rem' },
        { id: 'extendedSalesPrice', dataType: 'Currency', label: this.$t('sales-order-line_extended-sales-price-abbreviated_label'), name: 'extendedSalesPrice', width: '10rem' }
      ] as IColumn[]
    }
  },
  methods: {
    ...mapMutations([
      'setAlert',
      'setBreadcrumbText'
    ]),
    ...mapActions([
      'addSalesOrder',
      'fetchAccounts',
      'fetchContacts',
      'fetchLoggedOnUser',
      'fetchOpportunities',
      'fetchQuotes',
      'fetchSalesOrder',
      'fetchSalesOrderStatuses',
      'fetchSalesOrderLines',
      'fetchUsers',
      'updateSalesOrder',
      'updateSalesOrderStatus'
    ]),
    getPagedLineItems(pageInfo: any) {
      this.fetchSalesOrderLines({ salesOrderId: this.salesOrder.id, pageInfo: { ...this.salesOrder.lines.paging, ...pageInfo } }).then(data => {
        this.salesOrder.lines = data
      })
    },
    navigateToConfigurator(e: any) {
      if (e.canConfigure === false) return
      this.$router.push({
        name: 'sales-order-configurator',
        params: { salesOrderId: this.salesOrder.id },
        query: { dealId: this.salesOrder.omniDealId, cartId: this.salesOrder.omniCartId, rootConfigurationId: e.omniRootConfigurationItemId || e.rootConfigurationId, configurationId: e.omniConfigurationItemId || e.configurationId }
      })
    },
    refreshCart() {
      this.$el.querySelector('.configurator-cart iframe')?.contentWindow.postMessage({ type: 'REFRESH-SESSION', reconfigureCart: true }, '*')  
    },
    refreshSalesOrder() {
      this.fetchSalesOrder({ salesOrderId: this.$route.params.salesOrderId }).then(data => {
        this.salesOrder = data
      })
    },
    saveSalesOrder() {
      this.validatorKey++
      this.$nextTick(() => {
        if (this.$el.querySelectorAll('#sales-order .form-input .invalid').length) return
        this.isSaving = true
        const action = this.salesOrder.id ? this.updateSalesOrder : this.addSalesOrder
        action({ salesOrder: this.salesOrder })
          .then((data) => {
            if (this.salesOrder.id) {
              this.salesOrder = data
              this.setBreadcrumbText({ id: this.salesOrder.id, text: this.salesOrder.name })
              this.readonly = true
            } else {
              this.$router.push({ path: '/refresh', query: { name: 'salesOrder', id: data.id } })
            }
            this.setAlert({ type: AlertType.Success, title: this.$t('reseller_sales-order-save-1_message'), message: this.$t('reseller_sales-order-save-2_message'), timeout: 3000 })
          })
          .finally(() => {
            this.isSaving = false
          })
      })
    },
    saveSalesOrderStatus() {
      this.updateSalesOrderStatus({ salesOrderId: this.salesOrder.id, salesOrderStatus: this.salesOrderStatus }).then((data) => {
        this.salesOrder = data
        if (this.isLocked) this.readonly = true
        JQuery('#sales-order-status').modal('hide')
      })
    },
    async showSalesOrderStatusModal() {
      const data = await this.fetchSalesOrderStatuses({ salesOrderId: this.salesOrder.id })
      this.salesOrderStatuses = data.map(item => { return { id: item.status, name: this.$t('sales-order_status-' + item.status.toLowerCase() + '_label'), action: item.actionName } })
      this.salesOrderStatus = {} as ISalesOrderStatus
      JQuery('#sales-order-status').modal()
    }
  }
})
